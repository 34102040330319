import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { auth, db } from "../firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function isAdmin(user) {
  return user && user.isAdmin;
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userStatus, setUserStatus] = useState("loading");
  const [loading, setLoading] = useState(true);

  const checkUserStatus = useCallback(async (userId) => {
    try {
      const userSettingsRef = doc(db, "userSettings", userId);
      const userSettingsSnap = await getDoc(userSettingsRef);

      if (userSettingsSnap.exists()) {
        return userSettingsSnap.data().status;
      } else {
        throw new Error("ユーザー設定が見つかりません");
      }
    } catch (error) {
      console.error("Error checking user status:", error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userSettingsRef = doc(db, "userSettings", user.uid);
          const userSettingsSnap = await getDoc(userSettingsRef);

          if (userSettingsSnap.exists()) {
            const userSettings = userSettingsSnap.data();

            // ユーザーの最終ログイン日時を更新
            await setDoc(
              userSettingsRef,
              {
                authLastLoginAt: new Date().toISOString(),
              },
              { merge: true }
            );

            // ステータスに関わらずユーザー情報を設定
            setCurrentUser({
              ...user,
              isAdmin: userSettings.role === "admin",
              status: userSettings.status,
              role: userSettings.role,
              deletionTime: userSettings.deletionTime,
            });
            setUserStatus(userSettings.status);
          } else {
            // ユーザー設定が存在しない場合はサインアウト
            await auth.signOut();
            setCurrentUser(null);
            setUserStatus("none");
          }
        } catch (error) {
          console.error("Error fetching user settings:", error);
          // エラーが発生した場合はサインアウト
          await auth.signOut();
          setCurrentUser(null);
          setUserStatus("none");
        }
      } else {
        setCurrentUser(null);
        setUserStatus("none");
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const updateUserStatus = async (userId, newStatus) => {
    if (userId) {
      try {
        const userSettingsRef = doc(db, "userSettings", userId);
        await setDoc(
          userSettingsRef,
          { status: newStatus },
          { merge: true }
        );
      } catch (error) {
        console.error("Error updating user status:", error);
        throw error;
      }
    } else {
      console.error("Cannot update user status: No user ID provided");
      throw new Error("No user ID provided");
    }
  };

  const value = {
    currentUser,
    userStatus,
    loading,
    isAdmin: () => isAdmin(currentUser),
    setUserStatus,
    updateUserStatus,
    checkUserStatus,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
