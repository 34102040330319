import React, { useState } from 'react';
import UserList from './UserList';
import UserDetails from './UserDetails';

function AdminDashboard() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleUserUpdate = (updatedUser) => {
    if (updatedUser === null) {
      setSelectedUser(null);
      setIsPopupOpen(false);
    } else {
      setSelectedUser(updatedUser);
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="admin-dashboard">
      <h1>管理者ダッシュボード</h1>
      <div className="dashboard-content">
        <UserList onSelectUser={handleSelectUser} />
        {isPopupOpen && selectedUser && (
          <UserDetails
            user={selectedUser}
            onUserUpdate={handleUserUpdate}
            onClose={handleClosePopup}
          />
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;