import React from 'react';
import { useShareUrl } from '../context/ShareUrlContext';

function ShareUrlComponent({ showTooltip = false }) {
  const { shareUrl, generateShareUrl, deleteShareUrl } = useShareUrl();

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => alert("URLがクリップボードにコピーされました"))
      .catch((err) => console.error("Failed to copy: ", err));
  };

  return (
    <div className="share-url-container">
      {shareUrl ? (
        <>
          <input
            type="text"
            value={shareUrl}
            readOnly
            className="share-url-input"
          />
          <div className="share-url-actions">
            <button
              onClick={copyToClipboard}
              className="action-button copy-button"
              title="URLをコピー"
            >
              <i className="fas fa-copy"></i>
            </button>
            <button
              onClick={deleteShareUrl}
              className="action-button delete-button"
              title="共有URLを削除"
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </>
      ) : (
        <button onClick={generateShareUrl} className="generate-button">
          共有<i className="fa-solid fa-arrow-up-from-bracket"></i>
          {showTooltip && (
            <span className="tooltip">スクリーンの共有URLを生成し、誰でも閲覧する事ができます。</span>
          )}
        </button>
      )}
    </div>
  );
}

export default ShareUrlComponent;