import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { ShareUrlProvider } from "./context/ShareUrlContext";
import UploadPage from "./components/UploadPage";
import ViewPage from "./components/ViewPage";
import Auth from "./components/Auth";
import SignUp from "./components/SignUp";
import AdminDashboard from "./components/AdminDashboard";
import Navigation from "./components/Navigation";
import ImageGallery from "./components/ImageGallery";
import ErrorPage from "./components/ErrorPage";
import PasswordReset from "./components/PasswordReset";
import ResetPasswordFinish from "./components/ResetPasswordFinish";

function AppRoutes() {
  const { currentUser, loading, userStatus } = useAuth();


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route
        path="/login"
        element={currentUser && userStatus === 'active' ? <Navigate to="/" replace /> : <Auth />}
      />
      <Route
        path="/signup"
        element={currentUser && userStatus === 'active' ? <Navigate to="/" replace /> : <SignUp />}
      />
      <Route
        path="/reset-password"
        element={<PasswordReset />}
      />
      <Route
        path="/"
        element={
          currentUser && userStatus === 'active' ? (
            <>
              <Navigation />
              <UploadPage />
            </>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/view"
        element={currentUser && userStatus === 'active' ? <ViewPage /> : <Navigate to="/login" replace />}
      />
      <Route path="/view/:sharedId" element={<ViewPage />} />
      <Route
        path="/gallery"
        element={
          currentUser && userStatus === 'active' ? (
            <>
              <Navigation />
              <ImageGallery />
            </>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/admin"
        element={
          currentUser && currentUser.role === "admin" ? (
            <>
              <Navigation />
              <AdminDashboard />
            </>
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/reset-password-finish" element={<ResetPasswordFinish />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <ShareUrlProvider>
        <Router>
          <AppRoutes />
        </Router>
      </ShareUrlProvider>
    </AuthProvider>
  );
}

export default App;