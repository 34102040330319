import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";

const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    return timestamp.toDate().toLocaleString();
  }
  return "N/A";
};

const UserDetails = ({ user, onUserUpdate, onClose }) => {
  const [editedUser, setEditedUser] = useState(user);

  useEffect(() => {
    setEditedUser(user);
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prev) => ({ ...prev, [name]: value }));
  };

  const updateUser = async () => {
    try {
      const userRef = doc(db, "userSettings", user.uid);
      await updateDoc(userRef, editedUser);
      onUserUpdate(editedUser);
      alert("User updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Error updating user");
    }
  };

  return (
    <div className="user-details-popup">
      <div className="popup-content user-details">
        <button className="close-button" onClick={onClose}>
          <i className="fa-solid fa-times"></i>
        </button>
        <h2>User Details</h2>
        <div>
          <label>UID: </label>
          <span>{user.uid}</span>
        </div>
        <div>
          <label>Email: </label>
          <input
            type="email"
            name="email"
            value={editedUser.email || ""}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Created At: </label>
          <span>{formatTimestamp(user.createdAt)}</span>
        </div>
        <div>
          <label>Last Login: </label>
          <span>{formatTimestamp(user.lastLoginAt)}</span>
        </div>
        <div>
          <label>Status: </label>
          <select
            name="status"
            value={editedUser.status || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Status</option>
            <option value="active">Active</option>
            <option value="pending">Pending</option>
            <option value="suspended">Suspended</option>
          </select>
        </div>
        <div>
          <label>Role: </label>
          <select
            name="role"
            value={editedUser.role || ""}
            onChange={handleInputChange}
          >
            <option value="">Select Role</option>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div>
          <label>Shared View Created At: </label>
          <span>{formatTimestamp(user.sharedViewCreatedAt)}</span>
        </div>
        <div>
          <label>Shared View ID: </label>
          <input
            type="text"
            name="sharedViewId"
            value={editedUser.sharedViewId || ""}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Background File Type: </label>
          <input
            type="text"
            name="backgroundFileType"
            value={editedUser.backgroundFileType || ""}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Background File URL: </label>
          <input
            type="text"
            name="backgroundFileUrl"
            value={editedUser.backgroundFileUrl || ""}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>BGM URL: </label>
          <input
            type="text"
            name="bgmUrl"
            value={editedUser.bgmUrl || ""}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Deletion Time: </label>
          <input
            type="text"
            name="deletionTime"
            value={editedUser.deletionTime || ""}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Effect Sound URL: </label>
          <input
            type="text"
            name="effectSoundUrl"
            value={editedUser.effectSoundUrl || ""}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Ground Position: </label>
          <input
            type="number"
            name="groundPosition"
            value={editedUser.groundPosition || ""}
            onChange={handleInputChange}
          />
        </div>
        <button onClick={updateUser}>Update User</button>
      </div>
    </div>
  );
};

export default UserDetails;
