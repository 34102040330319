import React, { createContext, useState, useContext, useEffect } from 'react';
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "./AuthContext";

const ShareUrlContext = createContext();

export function useShareUrl() {
  return useContext(ShareUrlContext);
}

export function ShareUrlProvider({ children }) {
  const [shareUrl, setShareUrl] = useState("");
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      fetchShareUrl();
    } else {
      setShareUrl("");
    }
  }, [currentUser]);

  const fetchShareUrl = async () => {
    try {
      const userSettingsRef = doc(db, "userSettings", currentUser.uid);
      const docSnap = await getDoc(userSettingsRef);
      if (docSnap.exists() && docSnap.data().sharedViewId) {
        setShareUrl(
          `${window.location.origin}/view/${docSnap.data().sharedViewId}`
        );
      } else {
        setShareUrl("");
      }
    } catch (error) {
      console.error("Error fetching share URL:", error);
    }
  };

  const generateShareUrl = async () => {
    try {
      const userSettingsRef = doc(db, "userSettings", currentUser.uid);
      const sharedViewId =
        Date.now().toString(36) + Math.random().toString(36).substr(2);
      await updateDoc(userSettingsRef, {
        sharedViewId: sharedViewId,
        sharedViewCreatedAt: new Date(),
      });
      const newShareUrl = `${window.location.origin}/view/${sharedViewId}`;
      setShareUrl(newShareUrl);
    } catch (error) {
      console.error("Error generating share URL:", error);
      alert("共有URLの生成に失敗しました。");
    }
  };

  const deleteShareUrl = async () => {
    try {
      const userSettingsRef = doc(db, "userSettings", currentUser.uid);
      await updateDoc(userSettingsRef, {
        sharedViewId: deleteField(),
        sharedViewCreatedAt: deleteField(),
      });
      setShareUrl("");
      alert("共有URLが削除されました。");
    } catch (error) {
      console.error("Error deleting share URL:", error);
      alert("共有URLの削除に失敗しました。");
    }
  };

  const value = {
    shareUrl,
    generateShareUrl,
    deleteShareUrl
  };

  return (
    <ShareUrlContext.Provider value={value}>
      {children}
    </ShareUrlContext.Provider>
  );
}