import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';

function ResetPasswordFinish() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [mode, setMode] = useState('');
  const [isVerifying, setIsVerifying] = useState(true);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('oobCode');
    const actionMode = queryParams.get('mode');

    console.log('oobCode:', code);
    console.log('mode:', actionMode);

    if (code && actionMode === 'resetPassword') {
      setOobCode(code);
      setMode(actionMode);
      verifyPasswordResetCode(auth, code)
        .then(() => {
          setIsVerifying(false);
        })
        .catch((error) => {
          console.error('Error verifying reset code:', error);
          setMessage('無効または期限切れのリンクです。もう一度パスワードリセットを試してください。');
          setIsVerifying(false);
        });
    } else {
      setMessage('無効なリクエストです。もう一度パスワードリセットを試してください。');
      setIsVerifying(false);
    }
  }, [location]);

  useEffect(() => {
    setPasswordsMatch(newPassword === confirmPassword && newPassword !== '');
  }, [newPassword, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordsMatch) {
      return;
    }
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage('パスワードが正常にリセットされました。');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('パスワードのリセットに失敗しました。もう一度お試しください。');
    }
  };

  if (isVerifying) {
    return <div>認証中...</div>;
  }

  return (
    <div className="auth-page">
      <div className="container">
        <div className="auth-form">
          <h2>新しいパスワードを設定</h2>
          {message && <p className={message.includes('正常') ? 'success' : 'error'}>{message}</p>}
          {!message && mode === 'resetPassword' && (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="newPassword">新しいパスワード</label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">パスワードの確認</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              {!passwordsMatch && newPassword && confirmPassword && (
                <p className="error">パスワードが一致しません。</p>
              )}
              <button type="submit" disabled={!passwordsMatch}>
                パスワードを変更
              </button>
            </form>
          )}
          <p className="toggle-auth">
            <a href="/login">ログインページに戻る</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordFinish;