import React, { useState, useRef, useEffect } from 'react';

const GroundSettingComponent = ({ backgroundUrl, backgroundType, onGroundPositionChange,initialGroundPosition  }) => {
  const [groundPosition, setGroundPosition] = useState(initialGroundPosition);
  const containerRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    setGroundPosition(initialGroundPosition);
  }, [initialGroundPosition]);

  const handleInteraction = (e) => {
    let clientY;
    if (e.type.startsWith('touch')) {
      // タッチイベントの場合
      if (e.touches.length === 0) return;
      clientY = e.touches[0].clientY;
    } else {
      // マウスイベントの場合
      if (e.buttons !== 1) return;
      clientY = e.clientY;
    }

    const rect = containerRef.current.getBoundingClientRect();
    const y = clientY - rect.top;
    const newPosition = Math.max(0, Math.min(100, (y / rect.height) * 100));
    setGroundPosition(newPosition);
    onGroundPositionChange(newPosition);
  };


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.currentTime = 1;
      });
    }
  }, [backgroundUrl]);

  return (
    <div
      ref={containerRef}
      className="ground-setting-container"
      style={{ position: 'relative', width: '100%', height: '200px' }}
      onMouseMove={handleInteraction}
      onMouseDown={handleInteraction}
      onTouchStart={handleInteraction}
      onTouchMove={handleInteraction}
    >
      {backgroundUrl && backgroundType.startsWith('image/') && (
        <img src={backgroundUrl} alt="背景" style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
      )}
      {backgroundUrl && backgroundType.startsWith('video/') && (
        <video
          ref={videoRef}
          src={backgroundUrl}
          style={{ width: '100%', height: '200px', objectFit: 'cover' }}
          muted
        />
      )}
      <div
        className="ground-line"
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: `${groundPosition}%`,
          height: '4px',
          backgroundColor: 'red',
          cursor: 'ns-resize',
          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 6px'
        }}
      />
    </div>
  );
};

export default GroundSettingComponent;