import { getIdToken } from "firebase/auth";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { storage, db, auth } from "../firebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import {
  doc,
  setDoc,
  getDoc,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import MovementSettings from "./MovementSettings";
import axios from "axios";
import GroundSettingComponent from "./GroundSettingComponent";
import AudioSettings from "./AudioSettings";
import ShareUrlComponent from "./ShareUrlComponent";

const MAX_BACKGROUND_SIZE = 20 * 1024 * 1024; // 20MB
const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10MB

function UploadPage() {
  const [image, setImage] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [currentBackgroundFile, setCurrentBackgroundFile] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadingBackground, setUploadingBackground] = useState(false);
  const [imageName, setImageName] = useState("");
  const [backgroundFileName, setBackgroundFileName] = useState("");
  const [backgroundFileType, setBackgroundFileType] = useState("");
  const [movementSettings, setMovementSettings] = useState({
    type: "walk",
    movement: "normal",
    speed: 0.5,
    size: "medium",
  });
  const { currentUser } = useAuth();
  const imageInputRef = useRef(null);
  const backgroundInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [deletionTime, setDeletionTime] = useState("unlimited");
  const [groundPosition, setGroundPosition] = useState(50);
  const BACKEND_URL =
    process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";
  const [backendProcessing, setBackendProcessing] = useState(false);
  const progressIntervalRef = useRef(null);

  useEffect(() => {
    const fetchUserSettings = async () => {
      if (currentUser) {
        const userSettingsRef = doc(db, "userSettings", currentUser.uid);
        const docSnap = await getDoc(userSettingsRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const storedDeletionTime = data.deletionTime;
          setDeletionTime(
            storedDeletionTime === "unlimited"
              ? "unlimited"
              : parseInt(storedDeletionTime) || "unlimited"
          );
          setGroundPosition(data.groundPosition || 50);
        }
      }
    };
    fetchUserSettings();
    const fetchBackgroundFile = async () => {
      if (currentUser) {
        const userSettingsRef = doc(db, "userSettings", currentUser.uid);
        const docSnap = await getDoc(userSettingsRef);
        if (docSnap.exists() && docSnap.data().backgroundFileUrl) {
          setCurrentBackgroundFile(docSnap.data().backgroundFileUrl);
          setBackgroundFileType(docSnap.data().backgroundFileType);
        }
      }
    };
    fetchBackgroundFile();
  }, [currentUser]);

  const handleDeletionTimeChange = async (e) => {
    const newDeletionTime = e.target.value;
    setDeletionTime(newDeletionTime);
    if (currentUser) {
      const userSettingsRef = doc(db, "userSettings", currentUser.uid);
      await setDoc(
        userSettingsRef,
        {
          deletionTime:
            newDeletionTime === "unlimited"
              ? "unlimited"
              : parseInt(newDeletionTime),
        },
        { merge: true }
      );
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > MAX_IMAGE_SIZE) {
        alert("画像ファイルのサイズは10MB以下にしてください。");
        e.target.value = ""; // 選択をクリア
        return;
      }
      setImage(e.target.files[0]);
      setImageName(e.target.files[0].name);
    }
  };

  const handleBackgroundFileChange = (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0].size > MAX_BACKGROUND_SIZE) {
        alert("背景ファイルのサイズは20MB以下にしてください。");
        e.target.value = ""; // 選択をクリア
        return;
      }
      setBackgroundFile(e.target.files[0]);
      setBackgroundFileName(e.target.files[0].name);
      setBackgroundFileType(e.target.files[0].type);
    }
  };

  const handleMovementSettingsChange = (newSettings) => {
    setMovementSettings((prevSettings) => ({
      ...prevSettings,
      ...newSettings,
    }));
  };

  const handleUpload = async () => {
    if (image && currentUser) {
      setUploadingImage(true);
      setUploadProgress(0);
      try {
        const storageRef = ref(
          storage,
          `images/${currentUser.uid}/${image.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 85; // 85%まで
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Upload error:", error);
            alert(`アップロードエラー: ${error.message}`);
            setUploadingImage(false);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            const idToken = await getIdToken(auth.currentUser);

            console.log("Sending movement settings:", movementSettings);

            try {
              setBackendProcessing(true);
              setUploadProgress(85); // サーバー処理開始

              // 85%から99%まで1秒に1%ずつ進める
              progressIntervalRef.current = setInterval(() => {
                setUploadProgress((prevProgress) => {
                  if (prevProgress < 99) {
                    return prevProgress + 1;
                  }
                  return prevProgress;
                });
              }, 1000);

              const response = await axios.post(
                `${BACKEND_URL}/api/process-image`,
                {
                  imageUrl: downloadURL,
                  movement: movementSettings.movement,
                  speed: movementSettings.speed,
                  size: movementSettings.size,
                  type: movementSettings.type,
                  deletionTime:
                    deletionTime === "unlimited"
                      ? "unlimited"
                      : parseInt(deletionTime, 10),
                },
                {
                  headers: {
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              );

              if (progressIntervalRef.current) {
                clearInterval(progressIntervalRef.current);
              }
              // サーバー処理が完了したら、100%にする
              setUploadProgress(100);
              console.log("Image processed:", response.data);
              alert("画像は正常にアップロードされ、処理されました");
              // リセット処理
              setImage(null);
              setImageName("");
              if (imageInputRef.current) {
                imageInputRef.current.value = "";
              }
              setUploadingImage(false);
              setBackendProcessing(false);
            } catch (error) {
              if (progressIntervalRef.current) {
                clearInterval(progressIntervalRef.current);
              }
              console.error("Error processing image:", error);
              alert(`画像の処理にエラーが発生しました: ${error.message}`);
              setUploadingImage(false);
              setBackendProcessing(false);
            }
          }
        );
      } catch (error) {
        console.error("Error initiating upload:", error);
        alert(`アップロード開始エラー: ${error.message}`);
        setUploadingImage(false);
      }
    }
  };

  // コンポーネントのアンマウント時にインターバルをクリアする
  useEffect(() => {
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, []);

  const handleBackgroundUpload = async () => {
    if (backgroundFile && currentUser) {
      setUploadingBackground(true);
      setUploadProgress(0);
      try {
        const storageRef = ref(
          storage,
          `backgrounds/${currentUser.uid}/${backgroundFile.name}`
        );

        const uploadTask = uploadBytesResumable(storageRef, backgroundFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Background upload error:", error);
            alert(`背景アップロードエラー: ${error.message}`);
            setUploadingBackground(false);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            await setDoc(
              doc(db, "userSettings", currentUser.uid),
              { backgroundFileUrl: downloadURL, backgroundFileType },
              { merge: true }
            );
            setCurrentBackgroundFile(downloadURL);
            alert("背景ファイルは正常にアップロードされました");
            setBackgroundFile(null);
            setBackgroundFileName("");
            if (backgroundInputRef.current) {
              backgroundInputRef.current.value = "";
            }

            setUploadingBackground(false);
            setUploadProgress(0);
          }
        );
      } catch (error) {
        console.error("背景ファイルのアップロードエラー:", error);
        alert("背景ファイルのアップロードエラー。もう一度お試しください。");
        setUploadingBackground(false);
        setUploadProgress(0);
      }
    }
  };

  const handleRemoveBackground = async () => {
    if (currentUser) {
      try {
        const userSettingsRef = doc(db, "userSettings", currentUser.uid);
        const docSnap = await getDoc(userSettingsRef);
        if (docSnap.exists() && docSnap.data().backgroundFileUrl) {
          const storageRef = ref(storage, docSnap.data().backgroundFileUrl);
          await deleteObject(storageRef);
        }
        await updateDoc(userSettingsRef, {
          backgroundFileUrl: deleteField(),
          backgroundFileType: deleteField(),
        });
        setCurrentBackgroundFile(null);
        alert("背景ファイルを削除しました。");
      } catch (error) {
        console.error("背景ファイルの削除に失敗しました。:", error);
        alert("背景ファイルの削除に失敗しました。もう一度お試しください。");
      }
    }
  };

  const clearImageSelection = () => {
    setImage(null);
    setImageName("");
    if (imageInputRef.current) {
      imageInputRef.current.value = "";
    }
  };

  const handleGroundPositionChange = (position) => {
    setGroundPosition(position);
    // Firestoreに保存する処理を追加
    if (currentUser) {
      const userSettingsRef = doc(db, "userSettings", currentUser.uid);
      setDoc(userSettingsRef, { groundPosition: position }, { merge: true });
    }
  };

  const clearBackgroundFileSelection = () => {
    setBackgroundFile(null);
    setBackgroundFileName("");
    if (backgroundInputRef.current) {
      backgroundInputRef.current.value = "";
    }
  };

  return (
    <div className="upload-page">
      <div className="container">
        <div className="upload-section">
          <p className="step">STEP 01</p>
          <h2>背景の設定</h2>
          <div className="upload-box">
            <h3>背景</h3>
            <div className="upload-controls">
              <label className="file-input-label">
                背景を選択
                <input
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleBackgroundFileChange}
                  ref={backgroundInputRef}
                />
              </label>
              <button
                onClick={handleBackgroundUpload}
                disabled={!backgroundFile || uploadingBackground}
                className="upload-button"
              >
                {uploadingBackground ? "アップロード中..." : "アップロード"}
              </button>
            </div>
            {backgroundFileName && (
              <div className="file-info">
                <span className="file-icon">
                  <i className="fa-regular fa-file"></i>
                </span>
                <span className="file-name">{backgroundFileName}</span>
                <button
                  className="file-clear"
                  onClick={clearBackgroundFileSelection}
                >
                  <i className="fa-solid fa-delete-left"></i>
                </button>
              </div>
            )}
            <div className="progress-and-delete-container">
              {uploadingBackground && <ProgressBar progress={uploadProgress} />}
            </div>
            {currentBackgroundFile && (
              <div className="background-preview">
                {backgroundFileType.startsWith("image/") ? (
                  <img src={currentBackgroundFile} alt="現在の背景" />
                ) : (
                  <video src={currentBackgroundFile} controls />
                )}
                <button onClick={handleRemoveBackground} className="delete-btn">
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            )}
          </div>
          <div className="note">
            <p>対応ファイル</p>
            <p>画像：jpg、png(20MB以下)</p>
            <p>動画：mp4、mov(20MB以下)</p>
          </div>
        </div>
        <div className="upload-section">
          <p className="step">STEP 02</p>
          <h2>地面の位置設定</h2>
          <GroundSettingComponent
            backgroundUrl={currentBackgroundFile}
            backgroundType={backgroundFileType}
            onGroundPositionChange={handleGroundPositionChange}
            initialGroundPosition={groundPosition}
          />
          <div className="note">
            <p>
              赤線をドラッグして地面の位置を調整して下さい。(スマートフォンの場合はタップして下さい。)
            </p>
          </div>
        </div>
        <div className="upload-section">
          <p className="step">STEP 03</p>
          <h2>音楽の設定</h2>
          <AudioSettings />
          <div className="note">
            <p>対応ファイル：mp3、mp4(BGM10MB・効果音1MB以下)</p>
            <p>※効果音は新規画像がスクリーンに登場した時に再生されます。</p>
          </div>
        </div>
        <div className="upload-section">
          <p className="step">STEP 04</p>
          <h2>非表示までの時間設定</h2>
          <select value={deletionTime} onChange={handleDeletionTimeChange}>
            <option value="unlimited">無制限</option>
            <option value="1">1分</option>
            <option value="2">2分</option>
            <option value="3">3分</option>
            <option value="4">4分</option>
            <option value="5">5分</option>
            <option value="6">6分</option>
            <option value="7">7分</option>
            <option value="8">8分</option>
            <option value="9">9分</option>
            <option value="10">10分</option>
            <option value="15">15分</option>
            <option value="20">20分</option>
            <option value="30">30分</option>
          </select>
          <div className="note">
            <p>
              アップロードされたお絵描きが表示されてから消えるまでの時間を設定できます。
            </p>
            <p>
              例：「1分」に設定すると、画像はアップロードから1分後にスクリーンから消えます。
            </p>
            <p>
              ※「無制限」に設定すると、お絵描き一覧から削除するまでスクリーンに残り続けます。
            </p>
            <p>
              ※設定を途中で変更する場合は、{" "}
              <Link to="/gallery"> お絵描き一覧 </Link>
              からすべての画像を削除した後に変更してください。
            </p>
          </div>
        </div>

        <div className="upload-section">
          <p className="step">STEP 05</p>
          <h2>お絵かきアップロード</h2>
          <MovementSettings
            settings={movementSettings}
            onSettingsChange={handleMovementSettingsChange}
          />

          <div className="upload-box">
            <h3>画像</h3>
            <div className="upload-controls">
              <label className="file-input-label">
                ファイルを選択
                <input
                  type="file"
                  onChange={handleImageChange}
                  ref={imageInputRef}
                />
              </label>
              <button
                onClick={handleUpload}
                disabled={!image || uploadingImage}
                className="upload-button"
              >
                {uploadingImage ? "アップロード中..." : "アップロード"}
              </button>
            </div>
            {imageName && (
              <div className="file-info">
                <span className="file-icon">
                  <i className="fa-regular fa-file"></i>
                </span>
                <span className="file-name">{imageName}</span>
                <button className="file-clear" onClick={clearImageSelection}>
                  <i className="fa-solid fa-delete-left"></i>
                </button>
              </div>
            )}
            <div className="progress-and-delete-container">
              {uploadingImage && <ProgressBar progress={uploadProgress} />}
            </div>
          </div>
          <div className="note">
            <p>対応ファイル：jpg、png(10MB以下)</p>
            <p>※画面を動く速さを0にするとその場に留まります。</p>
          </div>
        </div>
        <div className="upload-section">
          <p className="step">STEP 06</p>
          <h2>スクリーンを表示</h2>
          <a
            href="/view"
            target="_blank"
            rel="noopener noreferrer"
            className="file-input-label"
          >
            スクリーン表示 <i className="fa-solid fa-up-right-from-square"></i>
          </a>
          <a
            href="/gallery"
            rel="noopener noreferrer"
            className="file-input-label-b m-top"
          >
            アップしたお絵描きを確認
          </a>
          <div className="m-top">
            <ShareUrlComponent showTooltip={false} />
          </div>
          <div className="note">
            <p>
              表示されたページをサイネージなどのスクリーンに投影してください。
            </p>
            <p>共有を行うと、スクリーン共有用のURLが生成されます。</p>
            <p>
              このURLを共有すると、自身が作成したスクリーンを誰でも閲覧できるようになります。
            </p>
            <p>
              ※共有URLを削除して再生成すると、別のURLが発行されます。削除後に再生成した場合は、新しいURLを共有してください。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProgressBar({ progress }) {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}>
        <span className="progress-text">{Math.round(progress)}%</span>
      </div>
    </div>
  );
}

export default UploadPage;
