import React, { useState, useEffect } from "react";
import { storage, db } from "../firebaseConfig";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";

const MAX_BGM_SIZE = 10 * 1024 * 1024; // 10MB
const MAX_EFFECT_SOUND_SIZE = 1 * 1024 * 1024;

function AudioSettings() {
  const [bgm, setBgm] = useState(null);
  const [effectSound, setEffectSound] = useState(null);
  const [currentBgm, setCurrentBgm] = useState(null);
  const [currentEffectSound, setCurrentEffectSound] = useState(null);
  const [uploadingBgm, setUploadingBgm] = useState(false);
  const [uploadingEffectSound, setUploadingEffectSound] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { currentUser } = useAuth();



  useEffect(() => {
    const fetchAudioSettings = async () => {
      if (currentUser) {
        const userSettingsRef = doc(db, "userSettings", currentUser.uid);
        const docSnap = await getDoc(userSettingsRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setCurrentBgm(data.bgmUrl);
          setCurrentEffectSound(data.effectSoundUrl);
        }
      }
    };
    fetchAudioSettings();
  }, [currentUser]);

  const handleAudioChange = (e, type) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const maxSize = type === "bgm" ? MAX_BGM_SIZE : MAX_EFFECT_SOUND_SIZE;

      if (file.size > maxSize) {
        alert(`${type === "bgm" ? "BGM" : "効果音"}のファイルサイズは${maxSize / 1024 / 1024}MB以下にしてください。`);
        e.target.value = ''; // 選択をクリア
        return;
      }

      if (type === "bgm") {
        setBgm(file);
      } else {
        setEffectSound(file);
      }
    }
  };

  const uploadAudio = async (file, type) => {
    if (file && currentUser) {
      setUploadProgress(0);
      if (type === "bgm") {
        setUploadingBgm(true);
      } else {
        setUploadingEffectSound(true);
      }
      try {
        const storageRef = ref(
          storage,
          `audio/${currentUser.uid}/${type}/${file.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Error uploading audio:", error);
            alert(
              `${
                type === "bgm" ? "BGM" : "効果音"
              }のアップロードに失敗しました: ${error.message}`
            );
            setUploadingBgm(false);
            setUploadingEffectSound(false);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            const userSettingsRef = doc(db, "userSettings", currentUser.uid);
            await setDoc(
              userSettingsRef,
              {
                [`${type}Url`]: downloadURL,
              },
              { merge: true }
            );

            if (type === "bgm") {
              setCurrentBgm(downloadURL);
              setBgm(null);
              setUploadingBgm(false);
            } else {
              setCurrentEffectSound(downloadURL);
              setEffectSound(null);
              setUploadingEffectSound(false);
            }

            alert(
              `${type === "bgm" ? "BGM" : "効果音"}がアップロードされました`
            );
            setUploadProgress(0);
          }
        );
      } catch (error) {
        console.error("Error uploading audio:", error);
        alert(
          `${type === "bgm" ? "BGM" : "効果音"}のアップロードに失敗しました: ${
            error.message
          }`
        );
        setUploadingBgm(false);
        setUploadingEffectSound(false);
        setUploadProgress(0);
      }
    }
  };

  const handleRemoveAudio = async (type) => {
    if (currentUser) {
      try {
        const userSettingsRef = doc(db, "userSettings", currentUser.uid);
        const docSnap = await getDoc(userSettingsRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const audioUrl = type === "bgm" ? data.bgmUrl : data.effectSoundUrl;
          if (audioUrl) {
            const storageRef = ref(storage, audioUrl);
            await deleteObject(storageRef);
          }
          await updateDoc(userSettingsRef, {
            [`${type}Url`]: deleteField(),
          });
          if (type === "bgm") {
            setCurrentBgm(null);
          } else {
            setCurrentEffectSound(null);
          }
          alert(`${type === "bgm" ? "BGM" : "効果音"}を削除しました。`);
        }
      } catch (error) {
        console.error(
          `${type === "bgm" ? "BGM" : "効果音"}の削除に失敗しました。:`,
          error
        );
        alert(
          `${
            type === "bgm" ? "BGM" : "効果音"
          }の削除に失敗しました。もう一度お試しください。`
        );
      }
    }
  };

  return (
    <div className="audio-settings">
      <div>
        <div className="upload-box">
          <h3>BGM</h3>
          <div className="upload-controls">
            <label className="file-input-label">
              BGMを選択
              <input
                type="file"
                accept="audio/*"
                onChange={(e) => handleAudioChange(e, "bgm")}
              />
            </label>
            <button
              onClick={() => uploadAudio(bgm, "bgm")}
              disabled={!bgm || uploadingBgm}
              className="upload-button"
            >
              {uploadingBgm ? "アップロード中.." : "アップロード"}
            </button>
          </div>
          {bgm && (
            <div className="file-info">
              <span className="file-icon">
                <i className="fa-regular fa-file-audio"></i>
              </span>
              <span className="file-name">{bgm.name}</span>
              <button className="file-clear" onClick={() => setBgm(null)}>
                <i className="fa-solid fa-delete-left"></i>
              </button>
            </div>
          )}
          <div className="progress-and-delete-container">
            {uploadingBgm && <ProgressBar progress={uploadProgress} />}
            {currentBgm && (
              <>
                <audio controls src={currentBgm} />
                <button
                  onClick={() => handleRemoveAudio("bgm")}
                  className="delete-btn"
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </>
            )}
          </div>
        </div>

        <div className="upload-box">
          <h3 className="m-top">効果音</h3>
          <div className="upload-controls">
            <label className="file-input-label">
              効果音を選択
              <input
                type="file"
                accept="audio/*"
                onChange={(e) => handleAudioChange(e, "effectSound")}
              />
            </label>
            <button
              onClick={() => uploadAudio(effectSound, "effectSound")}
              disabled={!effectSound || uploadingEffectSound}
              className="upload-button"
            >
              {uploadingEffectSound ? "アップロード中.." : "アップロード"}
            </button>
          </div>
          {effectSound && (
            <div className="file-info">
              <span className="file-icon">
                <i className="fa-regular fa-file-audio"></i>
              </span>
              <span className="file-name">{effectSound.name}</span>
              <button
                className="file-clear"
                onClick={() => setEffectSound(null)}
              >
                <i className="fa-solid fa-delete-left"></i>
              </button>
            </div>
          )}
          <div className="progress-and-delete-container">
            {uploadingEffectSound && <ProgressBar progress={uploadProgress} />}
            {currentEffectSound && (
              <>
                <audio controls src={currentEffectSound} />
                <button
                  onClick={() => handleRemoveAudio("effectSound")}
                  className="delete-btn"
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProgressBar({ progress }) {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}>
        <span className="progress-text">{Math.round(progress)}%</span>
      </div>
    </div>
  );
}

export default AudioSettings;
