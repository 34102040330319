import React from 'react';

function AudioPermissionDialog({ onAllow, onDeny }) {
  return (
    <div className="audio-permission-dialog">
      <h2>音楽の再生許可</h2>
      <p>このページでは音楽を再生します。音楽の再生を許可しますか？</p>
      <div className="button-container">
        <button onClick={onAllow}>許可する</button>
        <button onClick={onDeny}>許可しない</button>
      </div>
    </div>
  );
}

export default AudioPermissionDialog;