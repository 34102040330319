import React, { useState, useEffect, useCallback } from "react";
import { db, storage } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  orderBy,
  getDoc,
  deleteDoc,
  doc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useAuth } from "../context/AuthContext";
import MovementSettings from "./MovementSettings";

// 動きの名前マッピング
const movementNames = {
  normal: "ふつう",
  sway: "ゆらゆら",
  spin: "ぐるぐる",
  stretch: "びょーん",
  vibrate: "ぶるぶる",
};

// サイズの名前マッピング
const sizeNames = {
  small: "ちいさい",
  medium: "ふつう",
  large: "おおきい",
};

const typeNames = {
  walk: "地上",
  fly: "浮遊",
};

// 値を名前に変換するヘルパー関数
const getMovementName = (value) => movementNames[value] || value;
const getSizeName = (value) => sizeNames[value] || value;
const getTypeName = (value) => typeNames[value] || value;

const formatSpeed = (speed) => {
  if (typeof speed === "number") {
    // 0-1の範囲を0-100%に変換
    return `${Math.round(speed * 100)}%`;
  } else if (typeof speed === "string") {
    const parsedSpeed = parseFloat(speed);
    if (!isNaN(parsedSpeed)) {
      return `${Math.round(parsedSpeed * 100)}%`;
    }
    return speed; // パースできない場合は元の文字列を返す
  }
  return "不明";
};

function ImageGallery() {
  const [images, setImages] = useState([]);
  const [editingImage, setEditingImage] = useState(null);
  const [tempSettings, setTempSettings] = useState(null);
  const { currentUser } = useAuth();
  const [deletionTime, setDeletionTime] = useState("unlimited");
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    // ユーザー設定を取得
    const fetchUserSettings = async () => {
      const userSettingsRef = doc(db, "userSettings", currentUser.uid);
      const docSnap = await getDoc(userSettingsRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setDeletionTime(data.deletionTime || "unlimited");
      }
    };
    fetchUserSettings();

    // 画像を取得
    const q = query(
      collection(db, "processedImages"),
      where("userId", "==", currentUser.uid),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedImages = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          timestamp: data.timestamp.toDate(),
        };
      });
      setImages(fetchedImages);
    });

    return () => unsubscribe();
  }, [currentUser]);

  // 1秒ごとに現在時刻を更新
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // 残り時間を計算する関数
  const calculateRemainingTime = useCallback(
    (uploadTime) => {
      if (deletionTime === "unlimited") return "unlimited";
      const totalDeletionTime = parseInt(deletionTime, 10) * 60 * 1000;
      return Math.max(0, totalDeletionTime - (currentTime - uploadTime));
    },
    [deletionTime, currentTime]
  );

  // 残り時間の表示
  const formatRemainingTime = (remainingTime) => {
    if (remainingTime === "unlimited") return "無制限";
    const minutes = Math.floor(remainingTime / 60000);
    const seconds = Math.floor((remainingTime % 60000) / 1000);
    return `${minutes}分${seconds}秒`;
  };

  const handleDelete = async (image) => {
    if (window.confirm("この画像を削除してもよろしいですか？")) {
      try {
        const imageRef = ref(storage, image.processedUrl);
        await deleteObject(imageRef);
        await deleteDoc(doc(db, "processedImages", image.id));
        console.log(
          "Image successfully deleted from both Storage and Firestore"
        );
        setImages((prevImages) =>
          prevImages.filter((img) => img.id !== image.id)
        );
      } catch (error) {
        console.error("Error deleting image:", error);
        alert(`画像の削除中にエラーが発生しました: ${error.message}`);
      }
    }
  };

  const handleEdit = (image) => {
    setEditingImage(image);
    setTempSettings({
      type: image.type,
      movement: image.movement,
      speed: image.speed,
      size: image.size,
    });
  };

  const handleUpdate = async () => {
    if (!editingImage || !tempSettings) return;

    try {
      const { type, movement, speed, size } = tempSettings;

      // 更新するフィールドを明示的に指定し、値が存在する場合のみ更新
      const updateData = {};
      if (type) updateData.type = type;
      if (movement) updateData.movement = movement;
      if (speed !== undefined) updateData.speed = parseFloat(speed);
      if (size) updateData.size = size;

      await updateDoc(doc(db, "processedImages", editingImage.id), updateData);

      setEditingImage(null);
      setTempSettings(null);
    } catch (error) {
      console.error("Error updating image:", error);
      alert(`画像の更新中にエラーが発生しました: ${error.message}`);
    }
  };

  const handleSettingsChange = (updatedSettings) => {
    setTempSettings((prevSettings) => ({
      ...prevSettings,
      ...updatedSettings,
    }));
  };

  const recalculateRemainingTimes = useCallback((newDeletionTime) => {
    setImages((prevImages) =>
      prevImages.map((image) => {
        if (newDeletionTime === "unlimited") {
          return {
            ...image,
            remainingTime: "unlimited",
            initialRemainingTime: "unlimited",
          };
        }
        const currentTime = Date.now();
        const uploadTime = image.timestamp.getTime();
        const newRemainingTime = Math.max(
          0,
          parseInt(newDeletionTime) * 60000 - (currentTime - uploadTime)
        );
        return {
          ...image,
          remainingTime: newRemainingTime,
          initialRemainingTime: newRemainingTime,
        };
      })
    );
  }, []);

  const handleBulkDelete = async () => {
    if (
      window.confirm(
        "すべての画像を削除してもよろしいですか？この操作は取り消せません。"
      )
    ) {
      setIsDeleting(true);
      try {
        for (const image of images) {
          const imageRef = ref(storage, image.processedUrl);
          await deleteObject(imageRef);
          await deleteDoc(doc(db, "processedImages", image.id));
        }
        setImages([]);
        console.log("All images successfully deleted");
      } catch (error) {
        console.error("Error deleting images:", error);
        alert(`画像の一括削除中にエラーが発生しました: ${error.message}`);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className="image-gallery">
      <div className="gallery-header">
        <h2>画像一覧</h2>
        <button
          className="bulk-delete-button"
          onClick={handleBulkDelete}
          disabled={isDeleting || images.length === 0}
        >
          {isDeleting ? "削除中..." : "すべての画像を削除"}
        </button>
      </div>
      <div className="gallery-list">
        {images.map((image) => (
          <div key={image.id} className="image-tile">
            <img src={image.processedUrl} alt="Processed" />
            <div className="image-info">
              <p>アップロード日時: {image.timestamp.toLocaleString()}</p>
              <p>タイプ: {getTypeName(image.type)}</p>
              <p>動き: {getMovementName(image.movement)}</p>
              <p>パターンの大きさ: {getSizeName(image.size)}</p>
              <p>速さ: {formatSpeed(image.speed)}</p>
              <p>
                残り表示時間:{" "}
                {formatRemainingTime(
                  calculateRemainingTime(image.timestamp.getTime())
                )}
              </p>
            </div>
            <div className="image-actions">
              <button onClick={() => handleEdit(image)}>編集</button>
              <button onClick={() => handleDelete(image)}>削除</button>
            </div>
          </div>
        ))}
        {editingImage && tempSettings && (
          <div className="edit-modal">
            <MovementSettings
              settings={tempSettings}
              onSettingsChange={handleSettingsChange}
            />
            <div className="modal-actions">
              <button className="confirm-button" onClick={handleUpdate}>
                確定
              </button>
              <button
                className="cancel-button"
                onClick={() => {
                  setEditingImage(null);
                  setTempSettings(null);
                }}
              >
                キャンセル
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageGallery;
