import { useState } from 'react';
import { auth, db } from "../firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../context/AuthContext";

export const useSignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { updateUserStatus } = useAuth();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!_\-@$&%]+/.test(password);

    if (password.length < minLength) {
      return "パスワードは8文字以上である必要があります。";
    } else if (!hasUpperCase) {
      return "パスワードは少なくとも1つの大文字を含む必要があります。";
    } else if (!hasLowerCase) {
      return "パスワードは少なくとも1つの小文字を含む必要があります。";
    } else if (!hasNumbers) {
      return "パスワードは少なくとも1つの数字を含む必要があります。";
    } else if (!hasSpecialChars) {
      return "パスワードは少なくとも1つの記号(!、_、-、@、$、&、%)を含む必要があります。";
    }

    return null;
  };

  const createUserSettings = async (userId, email) => {
    const userSettingsRef = doc(db, "userSettings", userId);

    try {
      await setDoc(userSettingsRef, {
        email: email,
        role: "user",
        status: "pending",
        deletionTime: "unlimited",
        createdAt: new Date(),
      });
      return true;
    } catch (error) {
      console.error("Error in createUserSettings:", error);
      throw error;
    } finally {
    }
  };


  const signUp = async (email, password) => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const passwordError = validatePassword(password);
      if (passwordError) {
        throw new Error(passwordError);
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await createUserSettings(userCredential.user.uid, email);
      await updateUserStatus(userCredential.user.uid, "pending");
      setSuccess(true);
    } catch (error) {
      console.error("Error in signUp process:", error);
      let errorMessage = "認証エラーが発生しました。もう一度お試しください。";

      if (error.code) {
        switch (error.code) {
          case "auth/email-already-in-use":
            errorMessage = "このメールアドレスは既に使用されています。";
            break;
          case "auth/invalid-email":
            errorMessage = "無効なメールアドレスです。";
            break;
          case "auth/weak-password":
            errorMessage = "パスワードが弱すぎます。より強力なパスワードを使用してください。";
            break;
          default:
            errorMessage = `エラー: ${error.message}`;
        }
      } else {
        errorMessage = error.message;
      }

      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return { signUp, isLoading, error, success };
};