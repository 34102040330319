import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, orderBy, limit, startAfter, where } from 'firebase/firestore';

const UserList = ({ onSelectUser }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'email', direction: 'asc' });
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const formatDate = useCallback((dateField) => {
    if (!dateField) return 'N/A';
    if (dateField instanceof Date) return dateField.toLocaleString();
    if (typeof dateField === 'object' && dateField.seconds) {
      return new Date(dateField.seconds * 1000).toLocaleString();
    }
    if (typeof dateField === 'string') {
      const date = new Date(dateField);
      return isNaN(date.getTime()) ? dateField : date.toLocaleString();
    }
    return 'Invalid Date';
  }, []);

  const fetchUsers = useCallback(async (isInitial = false) => {
    if (loading) return;
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching users with config:', { sortConfig, searchTerm });
      let userQuery = query(
        collection(db, 'userSettings'),
        orderBy('email', 'asc'),
        limit(20)
      );

      if (searchTerm) {
        userQuery = query(userQuery, where('email', '>=', searchTerm), where('email', '<=', searchTerm + '\uf8ff'));
      }

      if (lastVisible && !isInitial) {
        userQuery = query(userQuery, startAfter(lastVisible));
      }

      const userSettingsSnapshot = await getDocs(userQuery);

      if (userSettingsSnapshot.empty) {
        console.log('No users found');
        setHasMore(false);
        setLoading(false);
        return;
      }

      const usersData = userSettingsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          uid: doc.id,
          ...data,
          createdAt: formatDate(data.createdAt || data.authCreatedAt),
          lastLoginAt: formatDate(data.lastLoginAt || data.authLastLoginAt),
          email: data.email || 'N/A',
          status: data.status || 'N/A',
          role: data.role || 'N/A'
        };
      });

      console.log('Fetched users:', usersData);

      setLastVisible(userSettingsSnapshot.docs[userSettingsSnapshot.docs.length - 1]);
      setUsers(prevUsers => {
        const newUsers = isInitial ? usersData : [...prevUsers, ...usersData];
        const uniqueUsers = Array.from(new Map(newUsers.map(user => [user.uid, user])).values());
        console.log('Unique users:', uniqueUsers);
        return uniqueUsers;
      });
      setHasMore(userSettingsSnapshot.docs.length === 20);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again.');
    }
    setLoading(false);
  }, [searchTerm, lastVisible, formatDate]);

  useEffect(() => {
    fetchUsers(true);
  }, [fetchUsers]);

  const handleLoadMore = useCallback(() => {
    if (!loading && hasMore) {
      fetchUsers(false);
    }
  }, [fetchUsers, loading, hasMore]);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    setUsers([]);
    setLastVisible(null);
    setHasMore(true);
    fetchUsers(true);
  }, [fetchUsers]);

  const requestSort = useCallback((key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  const getSortIcon = useCallback((name) => {
    if (sortConfig.key === name) {
      return sortConfig.direction === 'asc' ? 'fa-sort-up' : 'fa-sort-down';
    }
    return 'fa-sort';
  }, [sortConfig]);

  const sortedUsers = useMemo(() => {
    console.log('Sorting users', users.length);
    let sortableUsers = [...users];
    if (sortConfig.key) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    console.log('Sorted users', sortableUsers.length);
    return sortableUsers;
  }, [users, sortConfig]);

  const renderUserRow = useCallback((user) => (
    <tr key={user.uid} onClick={() => onSelectUser(user)}>
      <td>{user.email}</td>
      <td>{user.uid}</td>
      <td>{user.createdAt}</td>
      <td>{user.lastLoginAt}</td>
      <td>{user.status}</td>
      <td>{user.role}</td>
    </tr>
  ), [onSelectUser]);

  return (
    <div className="user-list">
      <h2>User List</h2>
      <form onSubmit={handleSearch} className="search-form">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search users by email..."
        />
        <button type="submit">Search</button>
      </form>
      {error && <div className="error">{error}</div>}
      <table>
        <thead>
          <tr>
            <th onClick={() => requestSort('email')}>Email <i className={`fa-solid ${getSortIcon('email')}`}></i></th>
            <th onClick={() => requestSort('uid')}>UID <i className={`fa-solid ${getSortIcon('uid')}`}></i></th>
            <th onClick={() => requestSort('createdAt')}>Created At <i className={`fa-solid ${getSortIcon('createdAt')}`}></i></th>
            <th onClick={() => requestSort('lastLoginAt')}>Last Login <i className={`fa-solid ${getSortIcon('lastLoginAt')}`}></i></th>
            <th onClick={() => requestSort('status')}>Status <i className={`fa-solid ${getSortIcon('status')}`}></i></th>
            <th onClick={() => requestSort('role')}>Role <i className={`fa-solid ${getSortIcon('role')}`}></i></th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map(renderUserRow)}
        </tbody>
      </table>
      {loading && (
        <div className="loading">
          <i className="fa-solid fa-spinner fa-spin"></i> Loading users...
        </div>
      )}
      {!loading && hasMore && (
        <button onClick={handleLoadMore} className="load-more">
          Load More
        </button>
      )}
    </div>
  );
};

export default React.memo(UserList);