import React from "react";

// ImageGalleryと同じ動きの名前マッピングを使用
const movementNames = {
  normal: "ふつう",
  sway: "ゆらゆら",
  spin: "ぐるぐる",
  stretch: "びょーん",
  vibrate: "ぶるぶる",
};

// ImageGalleryと同じサイズの名前マッピングを使用
const sizeNames = {
  small: "ちいさい",
  medium: "ふつう",
  large: "おおきい",
};

const typeNames = {
  walk: "地上",
  fly: "浮遊",
};

const movements = Object.entries(movementNames).map(([value, name]) => ({
  name,
  value,
}));
const sizes = Object.entries(sizeNames).map(([value, name]) => ({
  name,
  value,
}));
const types = Object.entries(typeNames).map(([value, name]) => ({
  name,
  value,
}));

function MovementSettings({ settings, onSettingsChange }) {
  const handleChange = (key, value) => {
    if (key === 'speed') {
      // speedは数値として扱う
      onSettingsChange({ [key]: parseFloat(value) });
    } else {
      // その他のフィールドは文字列として扱う
      onSettingsChange({ [key]: value });
    }
  };

  const handleSpeedChange = (e) => {
    const speed = parseFloat(e.target.value);
    onSettingsChange({ speed: isNaN(speed) ? 0.5 : speed });
  };

  const handleRandomSettings = () => {
    const randomMovement =
      movements[Math.floor(Math.random() * movements.length)].value;
    const randomSize = sizes[Math.floor(Math.random() * sizes.length)].value;
    const randomSpeed = Math.random();
    const randomType = types[Math.floor(Math.random() * types.length)].value;
    onSettingsChange({
      movement: randomMovement,
      size: randomSize,
      speed: randomSpeed,
      type: randomType,
    });
  };

  const formatSpeed = (speed) => {
    if (speed === undefined || speed === null) {
      return "50%";  // デフォルト値
    }
    if (typeof speed === "number") {
      return `${Math.max(0, Math.min(100, Math.round(speed * 100)))}%`;
    }
    if (typeof speed === "string") {
      const parsedSpeed = parseFloat(speed);
      if (!isNaN(parsedSpeed)) {
        return `${Math.max(0, Math.min(100, Math.round(parsedSpeed * 100)))}%`;
      }
    }
    return "不明";
  };


  return (
    <div className="settings-container">
      <h3>動きの設定</h3>
      <button className="random-button" onClick={handleRandomSettings}>
        ランダム選択
      </button>
      <div className="setting-group">
        <label htmlFor="type">動きのタイプ：</label>
        <select
          id="type"
          value={settings.type}
          onChange={(e) => handleChange("type", e.target.value)}
        >
          {types.map((t) => (
            <option key={t.value} value={t.value}>
              {t.name}
            </option>
          ))}
        </select>
      </div>
      <div className="setting-group">
        <label htmlFor="movement">動きのパターン：</label>
        <select
          id="movement"
          value={settings.movement}
          onChange={(e) => handleChange("movement", e.target.value)}
        >
          {movements.map((m) => (
            <option key={m.value} value={m.value}>
              {m.name}
            </option>
          ))}
        </select>
      </div>
      <div className="setting-group">
        <label htmlFor="size">パターンの大きさ：</label>
        <select
          id="size"
          value={settings.size}
          onChange={(e) => handleChange("size", e.target.value)}
        >
          {sizes.map((s) => (
            <option key={s.value} value={s.value}>
              {s.name}
            </option>
          ))}
        </select>
      </div>
      <div className="setting-group">
        <label htmlFor="speed">
          画面を動く速さ：{formatSpeed(settings.speed)}
        </label>
        <div className="speed-slider-container">
          <input
            type="range"
            id="speed"
            min="0"
            max="1"
            step="0.1"
            value={settings.speed !== undefined ? settings.speed : 0.5}
            onChange={handleSpeedChange}
            className="speed-slider"
          />
        </div>
        <div className="speed-labels">
          <span>おそい</span>
          <span>ふつう</span>
          <span>はやい</span>
        </div>
      </div>
    </div>
  );
}

export default MovementSettings;
