import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function ErrorPage() {
  const location = useLocation();
  const message = location.state?.message || 'ページが見つかりません。';

  return (
    <div className="error-page">
      <h1>エラー</h1>
      <p>{message}</p>
      <Link to="/">ホームに戻る</Link>
    </div>
  );
}

export default ErrorPage;