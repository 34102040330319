import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('パスワードリセットのメールを送信しました。メールをご確認ください。');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setMessage('パスワードリセットメールの送信に失敗しました。もう一度お試しください。');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="auth-page">
      <div className="container">
        <div className="auth-form">
          <h2>パスワードリセット</h2>
          {message && (
            <p className={`message ${message.includes('送信しました') ? 'success' : 'error'}`}>
              {message}
            </p>
          )}
          <form onSubmit={handleResetPassword}>
            <div className="form-group">
              <label htmlFor="email">メールアドレス</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" disabled={isLoading}>
              {isLoading ? '送信中...' : 'パスワードリセット'}
            </button>
          </form>
          <p className="toggle-auth">
            <Link to="/login">ログインページに戻る</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;